import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { DataSolicitudPipe } from '@core/pipes/data-solicitud.pipe';
import { AuxiliaresService } from '@core/services/auxiliares.service';
import { ChoizMessagesService } from '@shared/components/choiz-messages/choiz-messages.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RequestsService } from './requests.service';
import { Subscription, Subject, takeUntil } from 'rxjs';
import { TREATMENTS } from '@core/enums/tratamientos.enum';
import { xImageMyAccount } from '@core/interceptors/auth.interceptor';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RequestsDetailService {

  private http = inject(HttpClient);
  private _auxiliares = inject(AuxiliaresService);
  private _requests = inject(RequestsService);
  private fb = inject(FormBuilder);
  private solicitudPipe = inject(DataSolicitudPipe);
  private _cms = inject(ChoizMessagesService);
  private sanitizer = inject(DomSanitizer);
  private ngxService = inject(NgxUiLoaderService);

  comment: any = '';

  loadedData: boolean = false;
  loaded: boolean = false;
  loadData$: EventEmitter<boolean> = new EventEmitter<boolean>();
  createdStucture: boolean = false;

  selectedRequest: any = {};

  history: any;

  public cards: any = [];
  public details: any = [];
  public form: FormGroup = new FormGroup({});

  private subscriptions: Subscription = new Subscription;
  private unsubscribe$ = new Subject<void>();

  getData(){
    return new Promise((resolve, reject) => {
      Promise.all([
        this.getJsonDefinition(),
        this.getDataAux()
      ]).then(resp => {
        resolve(resp)

      }).catch(e => reject(e))
    })
  }

  async createDefinition(){
    this.getDataAux();
    this.generateStructure();
  }

  async getJsonDefinition() {
    return new Promise(async (resolve, reject) => {
        this.http.get(`assets/data/detailsRequestsChat.json`).subscribe(async (defRequests: any) => {
        this.details = await defRequests.REQUESTS;
        this.getDataAux();
        resolve(true);
      })
    })

  }

  generateStructure(){
    this.cards = this.details.cards;
    this.generateForm();
    this.cards.forEach((card, i) => {
      this.getOptions(card);
    })
  }

  async generateForm(){
    this.form = this.fb.group({});
    await this.generateFormRequests();
    await this.generateHistoryForm();
    await this.setDataFormRequest();
  }

  async generateFormRequests(){
    this.createdStucture = false;
    this.form = this.fb.group({
      user: this.fb.group({
        name: [ {value: '', disabled: true}, [] ],
        lastName: [ {value: '', disabled: true}, [] ],
        birthSex: [ {value: '', disabled: true}, [] ],
        selfGender: [ {value: '', disabled: true}, [] ],
        birthDay: [ {value: '', disabled: true}, [] ],
        telephone: [ {value: '', disabled: true}, [] ],
        email: [ {value: '', disabled: true}, [] ],
      }),
      request: this.fb.group({
        combo: [ {value: '', disabled: true}, [] ],
        frequency: [ {value: '', disabled: true}, [] ],
        formula: [ {value: '', disabled: true}, [] ],
        method: [ {value: '', disabled: true}, [] ],
        brand: [ {value: '', disabled: true}, [] ],
        quantity: [ {value: '', disabled: true}, [] ],
        recommendation_id: [ {value: '', disabled: true}, [] ],
        treatmentStartDate: [ {value: '', disabled: true}, [] ],
      }),
      comments: this.fb.group({
        comments: [ {value: '', disabled: false}, [] ]
      })
    })
  }

  async generateHistoryForm(){

    if(this.selectedRequest){
      const secciones = [];
      if(this.selectedRequest['sections']){
        this.selectedRequest['sections'].forEach(section => {
          secciones.push(section);
          let group: FormGroup = new FormGroup({});
          section.answers.forEach(answers => {
            let control;
                if(answers.values !== null){
                  control = this.fb.control({value: this.solicitudPipe.transform(answers.values), disabled: true})
                  group.addControl(answers.key, control);
                }
          })
          this.form.addControl(section.key, group);
        })
      }
      this.history = [];
      const dataMedical = this.selectedRequest['sections'][0]['answers'];
      this.history = dataMedical;
      this.loaded = true;
    }
  }

  async getDataAux(){

  }

  getOptions(card) {
    try{
      card.groups.forEach(group => {
            group.rowCol.forEach(rowcol => {
                rowcol.controls.forEach(control => {

                  if(control.type == 'DROPDOWN'){
                    switch(control.name){
                      case 'birthSex':
                        control.options = this._auxiliares.sexs();
                        break;
                      case 'selfGender':
                        control.options = this._auxiliares.genders();
                        break;
                    }
                  }
              })
            })
        })
      } catch(e){
      }
  }

  /* Formulario dinamico Historia medica */

  async crearFormulario(solicitud: any){
    if(solicitud){
      let idTreatment;
      switch(solicitud.treatment){
        case 'CONTRACEPTIVE' || 'ANTICONCEPTIVE':
          idTreatment = 1;
          break;
        case 'HAIR_LOSS_MA':
            idTreatment = 2;
            break;
        case 'HAIR_LOSS_FEM':
          idTreatment = 3;
          break;
        case 'ERECTILE_DYSFUNCTION':
          idTreatment = 4;
          break;
      }
    }
    solicitud != null && this.buildForm(solicitud);
  }

  buildForm(solicitud: any){
    const secciones = [];
    if(solicitud['sections']){
      solicitud['sections'].forEach(section => {
        secciones.push(section);
        let group: FormGroup = new FormGroup({});
        section.answers.forEach(answers => {
          let control;
              if(answers.values !== null){
                control = this.fb.control({value: this.solicitudPipe.transform(answers.values), disabled: true})
                group.addControl(answers.key, control);
              }
        })
        this.form.addControl(section.key, group);
      })
    }
    this.history = [];
    const dataMedical = solicitud['sections'][0]['answers'];
    this.history = dataMedical;
    this.loaded = true;
  }

  async setDataFormRequest(){
    this.history = [];
    this.createdStucture = true;
    if(this.selectedRequest){
      const dataUserRequest = this.selectedRequest['sections'][1]['answers'];
      const dataUser = this.selectedRequest['sections'][2]['answers'];

      this.form.get('user').patchValue({
        name: dataUser.find(x => x.key == 'name')?.values,
        lastName:  dataUser.find(x => x.key == 'last_name')?.values,
        birthSex:  dataUser.find(x => x.key == 'birth_sex')?.values,
        selfGender: dataUser.find(x => x.key == 'self_perceived_gender')?.values,
        birthDay: dataUser.find(x => x.key == 'birthday')?.values,
        telephone: dataUser.find(x => x.key == 'telephone') ? dataUser.find(x => x.key == 'telephone')?.values : '',
        email: dataUser.find(x => x.key == 'email') ? dataUser.find(x => x.key == 'email')?.values : '',

      })
      this.form.get('request').patchValue({
        combo: this.setDataInForm(dataUserRequest, 'combo'),
        frequency: this.setDataInForm(dataUserRequest, 'frequency'),
        method: this.setDataInForm(dataUserRequest, 'method'),
        brand: this.setDataInForm(dataUserRequest, 'brand'),
        quantity: this.setDataInForm(dataUserRequest, 'request_quantity'),
        recommendation_id: this.setDataInForm(dataUserRequest, 'recommendation_id'),
      }),
      this.form.get('comments').patchValue({
        comments: this.selectedRequest['comment'],
      })

      this.loadData$.emit(true);
      setTimeout(() => {
        this.ngxService.stop();
        this.loadedData = true;
      }, 2000)
    }

  }

  setDataInForm(data: any, key: string){
    if(data.find(x => x.key == key)){
      if(data.find(x => x.key == key).values){
        if(data.find(x => x.key == key).values.value){
          return data.find(x => x.key == key).values.value;
        } else {
          if(key == 'brand'){
            return data.find(x => x.key == key).values.brand;
          }
        }
        return data.find(x => x.key == key).values;
      }
      return '-'
    }
  }

  getImageFile(fileName:string){
    const chatEndpoint = environment.apiUrl;
		const chatFile = "resource";

		const headers = new HttpHeaders({
			'file-name': fileName,
			'bucket-folder-name': 'my-account/customer'
		})

		return this.http.get(`${chatEndpoint}/${chatFile}`, {
			headers,
			responseType: 'blob',
			context: xImageMyAccount()
		}).pipe(
			image => { return image }
		)
	}

  setComment(){
    this.comment = this.form.get('comments').value;
    const values = {
      comment: this.comment.comments,
      status: this.selectedRequest.status
    };
    this._requests.updateStateRequest(this.selectedRequest.id, values).pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
      this._cms.showMessage({ type: 'success', message: 'Actualizado correctamente', title: 'Actualizado', whatsapp: false, autoClose: true });
    })
  }

  // Imagenes
  async showImage(e, path: any, _data: any){
    e.preventDefault();
    let image: any;
    let fileName: any;
    await this.getImageFile(path).subscribe((data: any) => {
        let blob = new Blob([data], { type: "image/png" });
        let objectURL = URL.createObjectURL(blob);
        image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        fileName = `${_data['data']['name'].toLowerCase()}_${_data['data']['last_name'].toLowerCase()}_${path.toLowerCase()}`;
      })
      return {image, path, fileName}
  }

  getComboDataById(idCombo){
    let endpoint: string = ``;
    return this.http.get(environment.apiUrl)
  }
}
